import {
  Button,
  Paper,
  Grid,
  ListSubheader,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Select,
  MenuItem
} from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Icon } from '@iconify/react';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import { getDateFromStr } from 'utils/getDateFromStr';
import { COLORS } from 'utils/colors';

import BsTraffic from 'components/admin/bsTraffic/BsTraffic';
import ReplacementHistory from './ReplacementHistory';
import TelemetryLogDialog from './TelemetryLogDialog';
// import BsG3ManualEN from './manual/BsG3ManualEN';

import { useStyles } from './BaseStation.styled';

import ListItemWithSlider from '../ListItemWithSlider';
import ListItemWithText from '../ListItemWithText';

import { useSettingsContext, useSettingSelectionContext } from '../SettingsContext';

import EditDisplayNameDialog from './EditDisplayNameDialog';
import EditTimezoneDialog from './EditTimezoneDialog';
import AddBSDialog from './AddBSDialog';
import ReplaceBSDialog from './ReplaceBSDialog';
import MergeAccountsDialog from './MergeAccountsDialog';

const BaseStation = () => {
  const classes = useStyles();

  const settings = useSettingsContext();
  const { toggleSettings } = useSettingSelectionContext();

  const [isDisplayNameDialog, setIsDisplayNameDialog] = useState();
  const [isTimezoneDialog, setIsTimezoneDialog] = useState();

  const [telemetryIsOpen, setTelemetryIsOpen] = useState(false);

  const [isAddBSDialog, setIsAddBSDialog] = useState();
  const [isReplaceBSDialog, setIsReplaceBSDialog] = useState();
  const [isMergeAccountsDialog, setIsMergeAccountsDialog] = useState();

  const { i18n, isAdmin, reduxBaseStation, baseStations, bsConf, isGariUser } = useSelector(
    (store) => ({
      i18n: store.i18n,
      isAdmin: store.isAdmin,
      reduxBaseStation: store.baseStation,
      baseStations: store.baseStations,
      bsConf: store.bsConf,
      isGariUser: store.isGariUser
    })
  );

  const dateFormat = useSelector(
    (store) => store.sessionInfo?.ui_preferences?.date_format || 'dd.MM.yyyy'
  );

  // By default select the last of bsGroup
  const [isMultiBS] = useState(bsConf?.bsGroup);
  const [selectedMultiBS, setSelectedMultiBS] = useState(bsConf?.bs_id);
  //   bsConf?.bsGroup ? bsConf.bsGroup[bsConf.bsGroup.length - 1] : bsConf?.bs_id
  // );
  const [baseStation, setBaseStation] = useState(
    bsConf.bsGroup
      ? baseStations.find(
          (b) =>
            b.bs_BS_ID ===
            (bsConf?.bsGroup ? bsConf.bsGroup[bsConf.bsGroup.length - 1] : bsConf?.bs_id)
        )
      : reduxBaseStation
  );

  const [mergeable] = useState(
    !isGariUser && baseStation?.bs_BS_ID && baseStation?.bs_BS_ID.substring(0, 1) === 'F'
  );

  const changeMultiBS = (event) => {
    const bsID = event.target.value;
    setSelectedMultiBS(bsID);
    setBaseStation(baseStations.find((b) => b.bs_BS_ID === bsID));
  };

  const BsSelection = isMultiBS ? (
    <div className={classes.multiBS}>
      <Typography variant='body1' className={classes.multiBSSelect}>
        {`${i18n.sel_trg_system}${i18n.colon}`}
      </Typography>
      <Select
        style={{ margin: '0 10px' }}
        value={selectedMultiBS}
        onChange={(e) => changeMultiBS(e)}
      >
        {bsConf.bsGroup.map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  ) : null;

  return (
    <div className={classes.bsMainContainer}>
      <Paper component='div' className={classes.bsList}>
        {BsSelection}
        {isAdmin && <BsTraffic bsId={selectedMultiBS || baseStation.bs_BS_ID} />}
        {isAdmin && <ReplacementHistory bsId={selectedMultiBS || baseStation.bs_BS_ID} />}
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12}>
            <ListItem button onClick={() => setIsDisplayNameDialog(true)}>
              <ListItemText
                primary={i18n.display_name || 'Display name'}
                secondary={baseStation.display_name || baseStation.bs_BS_ID}
              />
            </ListItem>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ListItem>
              <ListItemText
                primary={i18n.bs_id || 'Base station ID'}
                secondary={baseStation.bs_BS_ID}
              />
            </ListItem>
            <Divider />
            <Grid item xs={12}>
              <ListItem button onClick={() => setIsTimezoneDialog(true)}>
                <ListItemText
                  primary={i18n.timezone || 'Timezone'}
                  secondary={baseStation.timezone}
                />
              </ListItem>
            </Grid>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ListItem>
              <ListItemText
                primary={i18n.bs_activation_date || 'Activation date'}
                secondary={
                  baseStation.activation_date &&
                  format(getDateFromStr(baseStation.activation_date), dateFormat)
                }
              />
            </ListItem>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ListItem>
              <ListItemText
                primary={i18n.bs_type || 'Base station type'}
                secondary={`${baseStation.bsModel}${
                  isAdmin && baseStation.freq ? ` - ${baseStation.freq} MHz` : ''
                }`}
              />
            </ListItem>
            <Divider />
          </Grid>
          {isAdmin && baseStation?.telemetry ? (
            <Grid item xs={12}>
              <ListItem>
                <ListItemText
                  primary='Mobile network'
                  // secondary={JSON.stringify(baseStation?.telemetry)}
                />
              </ListItem>
              <div
                aria-hidden='true' // enables onClick for div
                onClick={() => setTelemetryIsOpen(true)}
                className={classes.mobileInfo}
              >
                <table>
                  <tbody>
                    <tr>
                      <td style={{ padding: '5px 10px', borderRight: '1px solid #ccc' }}>
                        {baseStation.telemetry.operator}
                      </td>
                      <td style={{ padding: '5px 10px', borderRight: '1px solid #ccc' }}>
                        {baseStation.telemetry.net}
                      </td>
                      <td style={{ padding: '5px 10px' }}>{baseStation?.telemetry.mobile_rssi}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Divider />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <ListItem>
              <ListItemText
                primary={i18n.firmware_v || 'Firmware version'}
                secondary={`${baseStation?.firmware_ver} ${baseStation?.rev || ''}`}
              />
            </ListItem>
          </Grid>
        </Grid>

        {bsConf.tempIntervalDegrees && (
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <div style={{ display: 'flex' }}>
                <ListSubheader component='div' disableSticky>
                  {`${
                    i18n.accelerated_interval_title || 'High-temperature measurement acceleration'
                  }`}
                </ListSubheader>
              </div>
            </Grid>
            <Grid item xs={12}>
              <ListItemWithSlider
                i18n={i18n}
                id='tempIntervalDegrees'
                data={settings}
                toggleSettings={toggleSettings}
              />
            </Grid>
            <Grid item xs={12}>
              <ListItemWithSlider
                i18n={i18n}
                id='tempIntervalTime'
                data={settings}
                toggleSettings={toggleSettings}
              />
            </Grid>
          </Grid>
        )}

        {(isAdmin || bsConf.userBSIntervals) && (
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <div style={{ display: 'flex' }}>
                <ListSubheader component='div' disableSticky>
                  {`${isMultiBS ? `${bsConf.bs_id} ` : ''}${i18n.bs_configuration}`}
                </ListSubheader>
                {isAdmin ? (
                  <Icon
                    style={{ marginTop: 13, marginLeft: -10 }}
                    height={20}
                    icon={shieldAccount}
                  />
                ) : null}
              </div>
            </Grid>
            {isAdmin && Number(baseStation?.firmware_ver) >= 1.1 && (
              <Grid item xs={12}>
                <ListItemWithText
                  i18n={i18n}
                  id='heartbeat'
                  data={settings}
                  toggleSettings={toggleSettings}
                />
              </Grid>
            )}
            {isAdmin && (
              <Grid item xs={12}>
                <ListItemWithSlider
                  i18n={i18n}
                  id='communication_interval'
                  data={settings}
                  toggleSettings={toggleSettings}
                />
              </Grid>
            )}
            {isAdmin && Number(baseStation?.firmware_ver) >= 1.1 && (
              <Grid item xs={12}>
                <ListItemWithSlider
                  i18n={i18n}
                  id='timeout'
                  data={settings}
                  toggleSettings={toggleSettings}
                />
              </Grid>
            )}
            {isAdmin && Number(baseStation?.firmware_ver) >= 1.1 && (
              <Grid item xs={12}>
                <ListItemWithSlider
                  i18n={i18n}
                  id='buffer_max'
                  data={settings}
                  toggleSettings={toggleSettings}
                />
              </Grid>
            )}
            {isAdmin && Number(baseStation?.firmware_ver) >= 1.1 && (
              <Grid item xs={12}>
                <ListItemWithText
                  i18n={i18n}
                  id='clear_buffer'
                  data={settings}
                  toggleSettings={toggleSettings}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ListItemWithSlider
                i18n={i18n}
                id='measurement_interval_0'
                data={settings}
                toggleSettings={toggleSettings}
              />
            </Grid>
            {(isAdmin || (bsConf.userBSIntervals && bsConf.alarm_treshold_1 < 100)) &&
              Number(baseStation?.firmware_ver) < 3 && (
                <Grid item xs={12}>
                  <ListItemWithSlider
                    i18n={i18n}
                    id='measurement_interval_1'
                    data={settings}
                    toggleSettings={toggleSettings}
                  />
                </Grid>
              )}
            {(isAdmin ||
              (bsConf.userBSIntervals &&
                bsConf.alarm_treshold_1 < 100 &&
                bsConf.alarm_treshold_2 < 100)) &&
              Number(baseStation?.firmware_ver) < 3 && (
                <Grid item xs={12}>
                  <ListItemWithSlider
                    i18n={i18n}
                    id='measurement_interval_2'
                    data={settings}
                    toggleSettings={toggleSettings}
                  />
                </Grid>
              )}
            {isAdmin && Number(baseStation?.firmware_ver) >= 3 && (
              <Grid item xs={12}>
                <ListItemWithSlider
                  i18n={i18n}
                  id='receiving_time'
                  data={settings}
                  toggleSettings={toggleSettings}
                />
              </Grid>
            )}
          </Grid>
        )}

        {isAdmin && (
          <div className={classes.addBSContainer} style={{ marginBottom: 20 }}>
            <Button
              endIcon={isAdmin ? <Icon icon={shieldAccount} /> : null}
              color='secondary'
              variant='contained'
              onClick={() => setIsAddBSDialog(true)}
              disabled={isGariUser}
            >
              {i18n.add_basestation || 'Add Base Station'}
            </Button>
            {mergeable && (
              <Button
                endIcon={isAdmin ? <Icon icon={shieldAccount} /> : null}
                color='primary'
                style={{ color: COLORS.chetwodeBlue }}
                variant='outlined'
                onClick={() => setIsMergeAccountsDialog(true)}
              >
                {i18n.merge_account || 'Merge account'}
              </Button>
            )}
            <Button
              endIcon={isAdmin ? <Icon icon={shieldAccount} /> : null}
              color='primary'
              variant='outlined'
              onClick={() => setIsReplaceBSDialog(true)}
            >
              {i18n.replace_basestation || 'Replace BS'}
            </Button>
          </div>
        )}

        {isDisplayNameDialog && (
          <EditDisplayNameDialog
            isDisplayNameDialog={isDisplayNameDialog}
            setIsDisplayNameDialog={setIsDisplayNameDialog}
          />
        )}

        {isTimezoneDialog && (
          <EditTimezoneDialog
            isTimezoneDialog={isTimezoneDialog}
            setIsTimezoneDialog={setIsTimezoneDialog}
          />
        )}

        {isAddBSDialog && (
          <AddBSDialog isAddBSDialog={isAddBSDialog} setIsAddBSDialog={setIsAddBSDialog} />
        )}
        {isReplaceBSDialog && (
          <ReplaceBSDialog
            bs={bsConf.bs_id}
            isReplaceBSDialog={isReplaceBSDialog}
            setIsReplaceBSDialog={setIsReplaceBSDialog}
          />
        )}
        {isMergeAccountsDialog && (
          <MergeAccountsDialog
            isMergeAccountsDialog={isMergeAccountsDialog}
            setIsMergeAccountsDialog={setIsMergeAccountsDialog}
          />
        )}
        {telemetryIsOpen && (
          <TelemetryLogDialog
            BS_ID={baseStation.bs_BS_ID}
            setTelemetryIsOpen={setTelemetryIsOpen}
          />
        )}
        {/* <BsG3ManualEN mobile /> */}
      </Paper>
    </div>
  );
};

export default BaseStation;
