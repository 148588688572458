import { FormControl, MenuItem, Select, IconButton, Grid, ListSubheader } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { format, sub, isSameDay, startOfDay, differenceInHours } from 'date-fns';
import { InlineIcon } from '@iconify/react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import shieldAccount from '@iconify/icons-mdi/shield-account';
import CachedIcon from '@material-ui/icons/Cached';
import { VictoryChart, VictoryBar, VictoryAxis, VictoryVoronoiContainer } from 'victory';
import { adminStyles } from 'utils/sharedStyles';
import { COLORS } from 'utils/colors';
import SpecialTooltip from './SpecialBSTooltip';
import { getDataForChart, getBsCommDataDataFromAPI } from '../helpers/helper';
import ActivityDateDisplay from './ActivityDateDisplay';

const BsTraffic = ({ bsId }) => {
  const classes = adminStyles();

  const i18n = useSelector((store) => store.i18n);
  const dateFormat = useSelector(
    (store) => store.sessionInfo?.ui_preferences?.date_format || 'd/MM/yyyy'
  );
  const timeFormat = useSelector(
    (store) => store.sessionInfo?.ui_preferences?.time_format || 'HH:mm'
  );
  // const bsId = useSelector((store) => store.bsConf?.bs_id);
  const bsConf = useSelector((store) => store.bsConf);

  const [data, setData] = useState({});
  const hourOptions = [1, 3, 6, 12, 24];
  const [hours, setHours] = useState(3);
  const [offset, setOffset] = useState(0);

  const handleOffset = () => {
    const newOffset = offset - hours;
    setOffset(newOffset >= 0 ? newOffset : 0);
  };

  const date2 = sub(new Date(), { hours: offset });
  const date1 = sub(date2, { hours });
  const todaysOffset = differenceInHours(new Date(), startOfDay(new Date()));

  const handleDateSelection = (date) => {
    // Go to the beginning of the selected date
    const diff = differenceInHours(startOfDay(new Date()), startOfDay(date));
    setOffset(diff === 0 ? 0 : diff + todaysOffset - hours + 1);
  };

  const hourSelection = (
    <FormControl size='small' variant='outlined' className={classes.formControl}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          marginTop: -5
        }}
      >
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={hours}
          onChange={(e) => setHours(e.target.value)}
        >
          {hourOptions.map((option, i) => (
            <MenuItem key={option} value={option}>
              {option} {i === 0 ? i18n.hour : i18n.hours}
            </MenuItem>
          ))}
        </Select>
        <div
          style={{
            width: 50,
            paddingRight: 10,
            height: 20,
            marginTop: -10
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disableFuture
              autoOk
              variant='dialog'
              showTodayButton
              format='yyyy-MM-dd'
              margin='normal'
              value={date1}
              onChange={(date) => handleDateSelection(date)}
              // the inputfield is hidden, and only calendar button shows
              InputProps={{ disableUnderline: true }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </FormControl>
  );

  const offsetSelection = (
    <div>
      <div
        style={{
          width: '100%',
          padding: 0,
          marginBottom: -5,
          display: 'flex',
          justifyContent: 'center',
          fontSize: 12
        }}
      >
        <span style={{ fontsize: 10 }}>{format(date1, 'MMM-dd')}</span>
        <span style={{ minWidth: 20 }}> </span>

        <span style={isSameDay(date1, date2) ? { opacity: 0 } : { fontsize: 10 }}>
          {format(date2, 'MMM-dd')}
        </span>
      </div>
      <IconButton onClick={() => setOffset(offset + hours)} style={{ padding: 5 }}>
        <NavigateBeforeIcon />
      </IconButton>
      <span style={{ fontsize: 10 }}>
        {format(date1, 'HH:mm')} - {format(date2, 'HH:mm')}
      </span>

      <IconButton disabled={offset === 0} onClick={() => handleOffset()} style={{ padding: 5 }}>
        <NavigateNextIcon />
      </IconButton>
    </div>
  );

  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bootTS, setBootTS] = useState(bsConf?.last_boot_ts);
  const [confTS, setConfTS] = useState(bsConf?.last_conf_ts);
  const [dataTS, setDataTS] = useState(bsConf?.last_data_ts);

  useEffect(() => {
    const getOverviewData = async () => {
      setIsLoading(true);
      try {
        const bsCommData = await getBsCommDataDataFromAPI(bsId, offset, hours);
        const fullChartData = getDataForChart(bsCommData.data);
        setData((tempState) => ({
          ...tempState,
          ...fullChartData
        }));
        setBootTS(bsCommData.last_boot_ts);
        setConfTS(bsCommData.last_conf_ts);
        setDataTS(bsCommData.last_data_ts);

        setIsLoading(false);
      } catch (err) {
        return null;
      }
    };

    getOverviewData();
  }, [bsId, offset, hours, refresh, setBootTS, setConfTS, setDataTS]);

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12}>
        <div className={classes.flexDiv}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListSubheader component='div' disableSticky className={classes.flexDiv}>
              {`${bsId} Basestation Traffic`}
            </ListSubheader>
            <InlineIcon style={{ marginLeft: -10 }} height={20} icon={shieldAccount} />
          </div>
          <IconButton onClick={() => setRefresh(!refresh)} aria-label='refresh'>
            <CachedIcon />
          </IconButton>
        </div>
        <div>
          <div
            className={classes.flexDiv}
            style={{
              cursor: 'default',
              paddingLeft: 20,
              paddingRight: 20,
              boxSizing: 'border-box',
              marginBottom: 10
            }}
          >
            <ActivityDateDisplay type={1} date={bootTS} />
            <ActivityDateDisplay type={2} date={confTS} />
            <ActivityDateDisplay type={3} date={dataTS} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div>
          {isLoading ? (
            <p className={classes.loading}>Updating...</p>
          ) : data.noData ? (
            <p className={classes.loading}>
              No activity at {format(date1, 'MMM-dd HH:mm')} - {format(date2, 'MMM-dd HH:mm')}
            </p>
          ) : (
            <VictoryChart
              height={172}
              padding={{ top: 10, bottom: 10, left: 40, right: 40 }}
              domainPadding={20}
              containerComponent={
                <VictoryVoronoiContainer
                  mouseFollowTooltips
                  voronoiDimension='x'
                  labels={() => ' '}
                  labelComponent={
                    <SpecialTooltip
                      dateFormat={dateFormat}
                      timeFormat={timeFormat}
                      mode={0}
                      data={data}
                      windowWidth={window.innerWidth}
                      chartHei={document.getElementById('chartMainContainer')?.offsetHeight || 0}
                    />
                  }
                />
              }
            >
              {/* Boot Values */}
              <VictoryBar
                name='Boot values'
                data={data?.bootValues}
                domain={{ x: data.domain }}
                style={{
                  data: {
                    fill: 'rgb(246, 77, 175)',
                    width: Math.min(10, 20 / hours)
                  }
                }}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => [
                        {
                          target: 'data',
                          mutation: () => ({
                            style: {
                              fill: 'rgb(246, 77, 175)',
                              width: Math.min(10, 20 / hours),
                              stroke: COLORS.primaryColor,
                              strokeWidth: 1
                            }
                          })
                        }
                      ],
                      onMouseOut: () => [
                        {
                          target: 'data',
                          mutation: () => ({})
                        }
                      ]
                    }
                  }
                ]}
              />

              {/* Conf Values */}
              <VictoryBar
                name='Conf values'
                data={data?.confValues}
                domain={{ x: data.domain }}
                style={{
                  data: {
                    fill: 'rgb(34, 113, 111)',
                    width: Math.min(10, 20 / hours)
                  }
                }}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => [
                        {
                          target: 'data',
                          mutation: () => ({
                            style: {
                              fill: 'rgb(34, 113, 111)',
                              width: Math.min(10, 20 / hours),
                              stroke: 'rgb(246, 77, 175)',
                              strokeWidth: 1
                            }
                          })
                        }
                      ],
                      onMouseOut: () => [
                        {
                          target: 'data',
                          mutation: () => ({})
                        }
                      ]
                    }
                  }
                ]}
              />
              {/* Data Values */}
              <VictoryBar
                name='Data values'
                data={data?.dataValues}
                domain={{ x: data.domain }}
                style={{
                  data: {
                    fill: COLORS.secondaryColor,
                    stroke: COLORS.white,
                    strokeWidth: 0.5,
                    width: Math.max(3, Math.min(10, 15 / hours))
                  }
                }}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => [
                        {
                          target: 'data',
                          mutation: () => ({
                            style: {
                              fill: COLORS.secondaryColor,
                              stroke: COLORS.primaryColor,
                              width: Math.min(10, 20 / hours),
                              strokeWidth: 0.5
                            }
                          })
                        }
                      ],
                      onMouseOut: () => [
                        {
                          target: 'data',
                          mutation: () => ({})
                        }
                      ]
                    }
                  }
                ]}
              />
              {/* Heartbeat Values */}
              <VictoryBar
                name='Heartbeat values'
                data={data?.beatValues}
                domain={{ x: data.domain }}
                style={{
                  data: {
                    fill: COLORS.silverSonic,
                    stroke: COLORS.white,
                    strokeWidth: 0.5,
                    width: Math.max(3, Math.min(10, 15 / hours))
                  }
                }}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => [
                        {
                          target: 'data',
                          mutation: () => ({
                            style: {
                              fill: COLORS.silverSonic,
                              stroke: COLORS.secondaryColor,
                              width: Math.min(10, 20 / hours),
                              strokeWidth: 0.5
                            }
                          })
                        }
                      ],
                      onMouseOut: () => [
                        {
                          target: 'data',
                          mutation: () => ({})
                        }
                      ]
                    }
                  }
                ]}
              />
              {/* Bytes Axis (vertical) */}
              <VictoryAxis
                dependentAxis
                style={{
                  grid: {
                    stroke: 'rgba(0, 0, 0, 0.55)'
                  },
                  axis: {
                    strokeWidth: 0,
                    stroke: COLORS.whisperGray
                  },
                  tickLabels: {
                    fontSize: 7,
                    fontFamily: 'Roboto',
                    fill: 'rgba(0, 0, 0, 0.5)'
                  }
                }}
              />

              {/* Time Axis (horizontal) */}
              <VictoryAxis
                tickFormat={(x) => format(x, 'HH:mm:ss')}
                style={{
                  axis: {
                    stroke: COLORS.altoGray
                  },
                  tickLabels: {
                    fontSize: 7,
                    fontFamily: 'Roboto',
                    fill: 'rgba(0, 0, 0, 0.55)'
                  }
                }}
              />
            </VictoryChart>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '30%' }} />
          {offsetSelection}
          {hourSelection}
        </div>
      </Grid>
    </Grid>
  );
};

export default BsTraffic;
