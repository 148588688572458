import queryString from 'query-string';
import { isEmpty } from 'lodash';
// eslint-disable-next-line import/no-cycle
import jwtManager, { accessTokenKey, refreshTokenKey } from './jwtManager';

// const TOKEN = 'Qa_session';
const LOCAL_API_SERVER = 'http://localhost:4000';
const DEV_API_SERVER = 'https://dev-api.quanturi.app';
const PROD_API_SERVER = 'https://api.quanturi.app';

const getApiServer = (env) => {
  switch (env) {
    case 'dev':
      return DEV_API_SERVER;
    case 'new':
    case 'beta':
    case 'prod':
      return PROD_API_SERVER;
    default:
      return LOCAL_API_SERVER;
  }
};

export const getEnv = (env) => {
  switch (env) {
    case 'dev':
      return 'development';
    case 'new':
    case 'beta':
    case 'prod':
      return 'production';
    default:
      return 'development';
  }
};

export const API_SERVER = getApiServer(process.env.REACT_APP_API_ENV);

function addAuthHeader(headers, isRefresh) {
  const token = jwtManager.getToken(isRefresh ? refreshTokenKey : accessTokenKey);
  const defaultHeader = token ? { Authorization: `${token}` } : {};
  if (!isEmpty(headers)) {
    return { ...headers, ...defaultHeader };
  }
  return defaultHeader;
}

async function get(path) {
  const apiPath = API_SERVER + path;
  const response = await fetch(apiPath, {
    credentials: 'include',
    headers: addAuthHeader({}),
    mode: 'cors'
  });
  return response;
}

async function post(path, body, isRefresh) {
  try {
    const apiPath = API_SERVER + path;
    const headers = body ? { 'Content-Type': 'application/json' } : {};
    const response = await fetch(apiPath, {
      method: 'POST',
      credentials: 'include',
      headers: addAuthHeader(headers, isRefresh),
      body: JSON.stringify(body),
      mode: 'cors'
    });
    return response;
  } catch (e) {
    console.log('Post error: ', e.message);
    return null;
  }
}

async function parseResponse(response) {
  let body;
  try {
    body = await response.json();
  } catch (error) {
    body = {};
  }
  if (!response?.ok) {
    const error = {};
    error.status = response?.status;
    error.statusText = response?.statusText || '';
    return error;
  }
  return body;
}

/// /////// GET //////////

// General GET calls //
export async function getLogin(params) {
  const url = `/admin/login?${queryString.stringify(params)}`;
  const response = await parseResponse(await get(url));
  return response;
}

export async function getLoginAdmin(params) {
  const url = `/admin/loginAdmin?${queryString.stringify(params)}`;
  const response = await parseResponse(await get(url));
  return response;
}

export async function getAuth() {
  const url = '/auth';
  const response = await get(url);
  return parseResponse(response);
}

export async function getSessionInfo() {
  const url = '/client/getSessionInfo';
  const response = await get(url);
  return parseResponse(response);
}

export async function getUserInfo(params) {
  const url = `/client/userInfo?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getBaseStation(params) {
  const url = `/client/basestationArray?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getBsCommData(params) {
  const url = `/client/bsCommData?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getMobileTelemetry(params) {
  const url = `/client/getMobileTelemetry?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getBsConf(params) {
  const url = `/client/bsConf?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getSocket() {
  const url = `/client/soc`;
  const response = await get(url);
  return parseResponse(response);
}

// // Sensors measurements GET calls //
export async function getSensorsData(params) {
  const url = `/client/data?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}
export async function getSnapshot(params) {
  const url = `/client/snapshot?${queryString.stringify(params, { arrayFormat: 'bracket' })}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getSensorData(params) {
  const url = `/client/sensorData?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getSensorData2(params) {
  const url = `/client/data2?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getMinmaxData(params) {
  const url = `/client/minmaxData?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getAuxMinmax(params) {
  const url = `/client/auxMinmax?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

// export async function getCompactData(params) {
//   const url = `/client/compactData?${queryString.stringify(params)}`;
//   const response = await get(url);
//   return parseResponse(response);
// }

// // Login forgotten GET calls //
export async function getPasswordCodeByUsername(params) {
  const url = `/client/getPasswordCodeByUsername?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getUsernameSMS(params) {
  const url = `/client/getUsernameSMS?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

// // Payment info GET calls //
export async function getUpgradeInfo(params) {
  const url = `/client/getUpgradeInfo?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getRenewalInfo(params) {
  const url = `/client/getRenewalInfo?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

// Batches GET calls //
export async function getBatches(params) {
  const url = `/client/getBatches?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getBatchCounts(params) {
  const url = `/client/getBatchCounts?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getProcessPhases(params) {
  const url = `/client/getProcessPhases?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getChangedBatches(params) {
  const url = `/client/getChangedBatches?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

// Storages GET calls //

// // Storage Monitor testing //
export async function processStorageMonitors(params) {
  // params.verbose = 1; // Enable to get server prints
  console.log(`Calling processStorageMonitors(${params.BS_ID}): ${JSON.stringify(params)}`);
  const url = `/data/processStorageMonitors?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getStorages(params) {
  const url = `/client/getStorages?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getMonitors(params) {
  const url = `/client/getMonitors?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

// FC GET calls //
export async function getFcItem(params) {
  const url = `/fc/getFcItem?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function runScheduler(params) {
  const url = `/fc/runScheduler?${queryString.stringify(params)}`;
  const response = await get(url, params);
  return parseResponse(response);
}

export async function getRClogs(params) {
  const url = `/client/rcLogs?${queryString.stringify(params)}`;
  const response = await get(url, params);
  return parseResponse(response);
}
export async function getPhaseRClog(params) {
  const url = `/client/getPhaseRClog?${queryString.stringify(params)}`;
  const response = await get(url, params);
  return parseResponse(response);
}

// Admin GET calls //
export async function getBasestations(params) {
  const url = `/admin/basestations?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getUsers(params) {
  const url = `/admin/getusers?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getSIM(params) {
  const url = `/admin/getSIM?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getTestData(params) {
  const url = `/admin/getTestDataAll?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getValidityOverview(params) {
  const url = `/admin/getValidityOverview?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getEarlyBsCallsLog(params) {
  const url = `/admin/getEarlyBsCallsLog?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getBsLogs(params) {
  const url = `/admin/getBsLogs?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getBsLoggingList(params) {
  const url = `/admin/getBsLoggingList?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getGariCallLog(params) {
  const url = `/admin/getGariCallLog?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function findSystem(params) {
  const url = `/admin/findSystem?${queryString.stringify(params, {
    skipEmptyString: true
  })}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getSensorStats(params) {
  const url = `/admin/getSensorStats?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getLogs(params) {
  const url = `/admin/logs?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getAccessLogs(params) {
  const url = `/admin/accesslogs?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getRCList() {
  const url = `/admin/rcList`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getIdleGariSystems() {
  const url = `/admin/getIdleGariSystems`;
  const response = await get(url);
  return parseResponse(response);
}
export async function convertGariSystem(data) {
  const url = `/admin/convertGariSystem`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function getRcTestResponseEnabled(RC) {
  const url = `/admin/getRcTestResponseEnabled?id=${RC}`;
  const response = await get(url);
  return parseResponse(response);
}
export async function setRcTestResponseEnabled(data) {
  const url = `/admin/setRcTestResponseEnabled`;
  const response = await post(url, data);
  return parseResponse(response);
}
export async function getLogRcs() {
  const url = `/admin/getLogRcs`;
  const response = await get(url);
  return parseResponse(response);
}
export async function setRcTestResponse(data) {
  const url = `/admin/setRcTestResponse`;
  const response = await post(url, data);
  return parseResponse(response);
}
export async function getRCTestResponse(RC) {
  const url = `/fc/poll2?dev=1&id=${RC}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getAdminUsers(params) {
  const url = `/admin/adminUsers?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getServices(params) {
  const url = `/admin/getServices?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getKit(params) {
  const url = `/admin/getKit?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

// Insight GET calls //
export async function getInsightTrialTime(params) {
  const url = `/client/getInsightTrialTime?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

export async function getInsightSurvey(params) {
  const url = `/client/getInsightSurvey?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

// Verifix GET calls //
export async function getVerifications(params) {
  const url = `/client/getVerifications?${queryString.stringify(params)}`;
  const response = await get(url, params);
  return parseResponse(response);
}

export async function getVerifixData(params) {
  const url = `/client/verifixData?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

// Public API GET calls //
export async function generateApiKey(params) {
  const url = `/api/v1/generateKey?${queryString.stringify(params)}`;
  const response = await get(url, params);
  return parseResponse(response);
}

export async function getSummary(params) {
  const url = `/api/v1/getSummary?${queryString.stringify(params)}`;
  const response = await get(url, params);
  return parseResponse(response);
}

export async function getOverview(params) {
  const url = `/api/v1/getOverview?${queryString.stringify(params)}`;
  const response = await get(url, params);
  return parseResponse(response);
}

export async function getMeasurements(params) {
  const url = `/api/v1/getMeasurements?${queryString.stringify(params)}`;
  const response = await get(url, params);
  return parseResponse(response);
}

// Translations GET calls //
export async function getAnonymousTranslations(params) {
  const url = `/client/getAnonymousTranslations?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}
export async function getUserTranslations(params) {
  const url = `/client/getUserTranslations?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}
export async function getAdminTranslations(params) {
  const url = `/admin/getAdminTranslations?${queryString.stringify(params)}`;
  const response = await get(url);
  return parseResponse(response);
}

// //////// POST //////////
export async function postRefreshToken() {
  const url = '/admin/refreshToken';
  const response = await parseResponse(await post(url, null, true));
  const { accessToken, accessTokenExpiry } = response;
  if (accessToken && accessTokenExpiry) {
    // alert(`${tag || ''} - postRefreshToken: setToken for ${accessTokenExpiry}s`);
    jwtManager.setToken(accessTokenKey, accessToken, accessTokenExpiry);
  }
  return response;
}

export async function postLogout() {
  jwtManager.removeToken(accessTokenKey);
  jwtManager.removeToken(refreshTokenKey);
  const url = '/admin/logout';
  const response = await post(url);
  return parseResponse(response);
}

// General POST calls //
export async function postBsConf(params, data) {
  const url = `/client/bsConf?${queryString.stringify(params)}`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postBsDisplayName(data) {
  const url = `/client/updateBsDisplayName`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function setBsTimezone(data) {
  const url = `/client/setBsTimezone`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postActivateUsersSet(data) {
  const url = `/admin/activateUsersSet`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postToggleSensors(data) {
  const url = `/client/toggleSensors`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postTransferSensors(data) {
  const url = `/client/transferSensors`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postGroupBSs(data) {
  const url = `/admin/groupBSs`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postSensorConf(params, data) {
  const url = `/client/sensorConf?${queryString.stringify(params)}`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postUiPrefs(data) {
  const url = '/client/uiPrefs';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postResetPassword(data) {
  const url = '/client/resetPassword';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postUserInfo(data) {
  const url = '/client/userInfo';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postMinorUserInfo(data) {
  const url = '/client/minorUserInfo';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postDeleteSubUser(data) {
  const url = '/client/deleteSubUser';
  const response = await post(url, data);
  return parseResponse(response);
}
export async function postChangeUserPhone(data) {
  const url = '/client/changeUserPhone';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postChangeAdditionalPhone(data) {
  const url = '/client/changeAdditionalPhone';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postChangeUserPassword(data) {
  const url = '/client/changeUserPassword';
  const response = await post(url, data);
  return parseResponse(response);
}

// export async function postStartTrial(data) {
//   const url = '/client/startTrial';
//   const response = await post(url, data);
//   return parseResponse(response);
// }

export async function addNewUser(data) {
  const url = '/client/addNewUser';
  const response = await post(url, data);
  return parseResponse(response);
}

// // Payment POST calls //
export async function postCharge(data) {
  const url = '/charge';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postConfirmPayment(data) {
  const url = '/confirmPayment';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postInvoiceRequest(data) {
  if (data.planRenewal?.exp) {
    data.planRenewal.exp = `${data.planRenewal.exp.substr(0, 4)}-${data.planRenewal.exp.substr(
      4,
      2
    )}-${data.planRenewal.exp.substr(6, 2)}`;
  }
  const url = '/invoiceRequest';
  const response = await post(url, data);
  return parseResponse(response);
}

// Batches POST calls //
export async function postSaveBatch(data) {
  const url = '/client/saveBatch';
  const response = await post(url, data);
  return parseResponse(response);
}

// export async function postExportReport(data) {
//   const url = '/client/exportReport';
//   const response = await post(url, data);
//   return parseResponse(response);
// }

export async function saveProcessPhases(data) {
  const url = '/client/saveProcessPhases';
  const response = await post(url, data);
  return parseResponse(response);
}
// Storages POST calls //
// params: UID, item
export async function postSaveStorage(data) {
  const url = '/client/saveStorage';
  const response = await post(url, data);
  return parseResponse(response);
}
// params: UID, item
export async function postSaveMonitor(data) {
  const url = '/client/saveMonitor';
  const response = await post(url, data);
  return parseResponse(response);
}
// params: UID, itemID
export async function postRemoveStorage(data) {
  const url = '/client/removeStorage';
  const response = await post(url, data);
  return parseResponse(response);
}
// params: UID, itemID
export async function postRemoveMonitor(data) {
  const url = '/client/removeMonitor';
  const response = await post(url, data);
  return parseResponse(response);
}

// FC POST calls //
export async function setFcUse(params) {
  const url = `/fc/setFcUse?${queryString.stringify(params)}`;
  const response = await post(url, params);
  return parseResponse(response);
}

export async function setFcAssignment(params) {
  // Params: {fc_id, channel, batchRef, monitorRef, isManual, manualCommand, name}
  const url = `/fc/setFcAssignment?${queryString.stringify(params)}`;
  const response = await post(url, params);
  return parseResponse(response);
}

export async function clearFcAssignment(params) {
  // Params: {fc_id, channel, batchRef, monitorRef, isManual, manualCommand}
  const url = `/fc/clearFcAssignment?${queryString.stringify(params)}`;
  const response = await post(url, params);
  return parseResponse(response);
}

// params: FC_ID, isManual, command
export async function setFcManualCommand(data) {
  const url = '/fc/setFcManualCommand?';
  const response = await post(url, data);
  return parseResponse(response);
}

// Admin POST calls //
export async function postConfirmMobile(data) {
  const url = '/admin/confirmMobile';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function checkUserName(data) {
  const url = '/admin/checkUsername';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function checkGariUsername(data) {
  const url = '/admin/checkGariUsername';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function checkGariMigration(data) {
  const url = '/admin/checkGariMigration';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function migrateGariUser(data) {
  const url = '/admin/migrateGariUser';
  const response = await post(url, data);
  return parseResponse(response);
}
export async function confirmGariMobile(data) {
  const url = '/admin/confirmGariMobile';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postActivateKit(data) {
  const url = '/admin/activateKit';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function checkAccountMerge(data) {
  const url = '/admin/checkAccountMerge';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function doAccountMerge(data) {
  const url = '/admin/doAccountMerge';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postReplaceBS(data) {
  const url = '/admin/replaceBasestation';
  const response = await post(url, data);
  return parseResponse(response);
}
export async function getReplacementHistory(data) {
  const url = '/admin/getReplacementHistory';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postUndoBSActivation(data) {
  const url = '/admin/undoBSActivation';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postActivateService(data) {
  const url = '/admin/activateService';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function registerUser(data) {
  const url = '/admin/registerUser';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function confirmMobile(data) {
  const url = '/admin/confirmMobile';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function setPlanInfo(data) {
  const url = '/admin/setPlanInfo';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function setInsight(data) {
  const url = '/admin/setInsight';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function setSIMExpiration(data) {
  const url = '/admin/setSIMExpiration';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function setAdminUserGroup(data) {
  const url = '/admin/setAdminUserGroup';
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postBsLoggingStatus(data) {
  const url = `/admin/postBsLoggingStatus`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postInsightMigration(data) {
  const url = `/admin/insightMigration`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postInsightHold(data) {
  const url = `/admin/insightHold`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postInsightFreemiumTrial(data) {
  const url = `/admin/insightFreemiumTrial`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function registerService(data) {
  const url = `/admin/registerService`;
  const response = await post(url, data);
  return parseResponse(response);
}

// Insight POST calls //

export async function postInsightSurvey(data) {
  const url = `/client/saveInsightSurvey`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function postInsightStorages(data) {
  const url = `/client/saveInsightStorages`;
  const response = await post(url, data);
  return parseResponse(response);
}

// Verifix POST calls //
export async function saveVerification(data) {
  const url = `/client/saveVerification`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function startVerification(data) {
  const url = `/client/startVerification`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function stopVerification(data) {
  const url = `/client/stopVerification`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function deleteVerification(data) {
  const url = `/client/deleteVerification`;
  const response = await post(url, data);
  return parseResponse(response);
}

// Public API POST calls //
export async function activateSystem(data) {
  const url = `/api/v2/activateSystem`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function activateProbes(data) {
  const url = `/api/v2/activateProbes`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function deactivateBS(data) {
  const url = `/api/v2/deactivateBS`;
  const response = await post(url, data);
  return parseResponse(response);
}

export async function reactivateBS(data) {
  const url = `/api/v2/reactivateBS`;
  const response = await post(url, data);
  return parseResponse(response);
}

// Translations POST calls //
export async function updateTranslationFile(data) {
  const url = `/admin/updateTranslations`;
  const response = await post(url, data);
  return parseResponse(response);
}
