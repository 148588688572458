import {
  format,
  add,
  sub,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  endOfDay
} from 'date-fns';
import gradient from 'gradient-color';
import { store } from '../redux/store/configureStore';
import { getDateFromStr } from 'utils/getDateFromStr';
import { COLORS } from 'utils/colors';
import { isEmpty, intersection } from 'lodash';

export function isNil(value) {
  return value === null || value === undefined;
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})`
    : 'rgb(255,255,255)';
}
export function rgbTohex(rgbColor) {
  const [r, g, b] = rgbColor.split('rgb(')[1].split(')')[0].split(',');
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

const subC = hexToRgb('#4ea6dd');
const blue = hexToRgb('#6ebdbb');
const black = hexToRgb('#252525');

const nColors = 100;
const sub0grad = gradient(['#4ea6dd', '#6ebdbb'], nColors + 1);
const blueGreenGrad = gradient(['#6ebdbb', '#64c864'], nColors + 1);
const greenOrangeGrad = gradient(['#64c864', '#f3ab38'], nColors + 1);
const orangeRedGrad = gradient(['#f3ab38', '#de534e'], nColors + 1);
const redBlackGrad = gradient(['#de534e', '#252525'], nColors + 1);

export function getGradientColor(temperature, isSlider, isDefaultScale) {
  const s = store.getState();
  const degrees = s.sessionInfo?.ui_preferences?.degrees ?? '°C';

  if (degrees === '°F' && !!isSlider) {
    temperature = fToCelsius(temperature);
  }

  let scale =
    s.sessionInfo && s.sessionInfo.ui_preferences && s.sessionInfo.ui_preferences.color_steps
      ? s.sessionInfo.ui_preferences.color_steps
      : [0, 40, 60, 80, 100].map((a) => Number(a));

  if (isDefaultScale || scale.length < 5) {
    scale = [0, 40, 60, 80, 100];
  }
  if (temperature < 0) {
    return temperature <= -20 ? subC : sub0grad[Math.round((-temperature / 20) * nColors)];
  }
  if (temperature < scale[0]) {
    return blue;
  }
  if (temperature < scale[1]) {
    return blueGreenGrad[Math.round(((temperature - scale[0]) / (scale[1] - scale[0])) * nColors)];
  }
  if (temperature < scale[2]) {
    return greenOrangeGrad[
      Math.round(((temperature - scale[1]) / (scale[2] - scale[1])) * nColors)
    ];
  }
  if (temperature < scale[3]) {
    return orangeRedGrad[Math.round(((temperature - scale[2]) / (scale[3] - scale[2])) * nColors)];
  }
  if (temperature < scale[4]) {
    // div by 1.5 will soften going to black
    return redBlackGrad[
      Math.round((((temperature - scale[3]) / (scale[4] - scale[3])) * nColors) / 1.5)
    ];
  }
  return black;
}

const RGBtoHSV = (color) => {
  let h;
  let s;
  const r = color[0];
  const g = color[1];
  const b = color[2];
  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);

  const v = max;
  const delta = max - min;
  if (max !== 0) s = delta / max;
  // s
  else {
    // r = g = b = 0        // s = 0, v is undefined
    s = 0;
    h = -1;
    return [h, s, undefined];
  }
  if (r === max) h = (g - b) / delta;
  // between yellow & magenta
  else if (g === max) h = 2 + (b - r) / delta;
  // between cyan & yellow
  else h = 4 + (r - g) / delta; // between magenta & cyan
  h *= 60; // degrees
  if (h < 0) h += 360;
  if (Number.isNaN(h)) h = 0;
  return [h, s, v];
};

const HSVtoRGB = (color) => {
  let r;
  let g;
  let b;
  let h = color[0];
  const s = color[1];
  const v = color[2];
  if (s === 0) {
    // achromatic (grey)
    r = v;
    g = v;
    b = v;
    return [r, g, b];
  }
  h /= 60; // sector 0 to 5
  const i = Math.floor(h);
  const f = h - i; // factorial part of h
  const p = v * (1 - s);
  const q = v * (1 - s * f);
  const t = v * (1 - s * (1 - f));
  switch (i) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    default:
      // case 5:
      r = v;
      g = p;
      b = q;
      break;
  }
  return [r, g, b];
};

export const addIntensity = (rgbString, factor) => {
  // convert rgbString 'rgb(10,20,30)' to number array [10,20,30]
  const rgb1 = `${rgbString}`.substring(`${rgbString}`.indexOf('(') + 1);
  const rgbArray = rgb1
    .substring(0, rgb1.indexOf(')'))
    .split(',')
    .map((val) => Number(val));
  // Convert [r,g,b] to [h,s,v]
  const hsv = RGBtoHSV(rgbArray);
  // Apply factor to saturation
  hsv[1] *= factor;
  let adjustedRGB = HSVtoRGB(hsv);
  // Round values and ensure value is between 0-255
  adjustedRGB = adjustedRGB.map((val) => Math.round(Math.min(255, Math.max(0, val))));
  // Convert back to rgb string
  return `rgb(${adjustedRGB[0]}, ${adjustedRGB[1]}, ${adjustedRGB[2]})`;
};

export function getFaded(background, color, opacity) {
  const base = background
    .split('rgb(')[1]
    .split(')')[0]
    .split(',')
    .map((string) => parseInt(string));
  base.push(1);

  const added = color
    .split('rgb(')[1]
    .split(')')[0]
    .split(',')
    .map((string) => parseInt(string));
  added.push(opacity);

  const mix = [];

  mix[3] = 1 - (1 - added[3]) * (1 - base[3]); // alpha
  mix[0] = Math.round(
    (added[0] * added[3]) / mix[3] + (base[0] * base[3] * (1 - added[3])) / mix[3]
  ); // red
  mix[1] = Math.round(
    (added[1] * added[3]) / mix[3] + (base[1] * base[3] * (1 - added[3])) / mix[3]
  ); // green
  mix[2] = Math.round(
    (added[2] * added[3]) / mix[3] + (base[2] * base[3] * (1 - added[3])) / mix[3]
  ); // blue
  mix.pop();

  return `rgb(${mix.join(',')})`;
}

export function celsiusToF(cels, fDecimals) {
  const converted = (parseFloat(cels) * 9) / 5 + 32;
  if (isNil(fDecimals) || fDecimals < 1) {
    return +Math.round(converted);
  }
  return converted.toFixed(fDecimals);
}

export function tempFormat(degrees, temperature, nDecimals, fDecimals) {
  return isNil(temperature)
    ? '--'
    : degrees === '°C'
    ? isNil(nDecimals)
      ? Number(temperature).toFixed(1)
      : Number(temperature).toFixed(nDecimals)
    : celsiusToF(+temperature, fDecimals);
}

export function tempFormatDelta(degrees, temperature, nDecimals, fDecimals) {
  return isNil(temperature)
    ? '--'
    : degrees === '°C'
    ? isNil(nDecimals)
      ? Number(temperature).toFixed(1)
      : Number(temperature).toFixed(nDecimals)
    : isNil(fDecimals)
    ? Math.round(temperature * 1.8)
    : (temperature * 1.8).toFixed(fDecimals);
}

export function tempFormatDeltaToSave(degrees, temperature) {
  return degrees === '°C' ? temperature : temperature / 1.8;
}

export function fToCelsius(fahrenheit) {
  return +(((parseFloat(fahrenheit) - 32) * 5) / 9);
}

export function getHotest(data, startDate, endDate) {
  let m = data[0].y;
  data.forEach((point) => {
    if (point.x >= startDate && point.x < endDate) {
      const mTest = m === null ? -9999 : m;
      if (point.y > mTest) {
        m = point.y;
      }
    }
  });
  return m;
}

export function generateTimeline(data) {
  if (data === undefined) {
    return 'transparent';
  }
  let gradient = '(to right, ';
  const min = data[0].x;
  const max = data[data.length - 1].x;
  let startDate = min;
  let endDate = startDate + 24 * 3600000;
  let hotest;
  let color;
  while (endDate < max) {
    hotest = getHotest(data, startDate, endDate);
    color = hotest === null ? 'transparent' : getGradientColor(hotest);
    gradient += `${color}, `;
    startDate = endDate;
    endDate = startDate + 24 * 3600000;
  }
  hotest = getHotest(data, endDate - 24 * 3600000, max);
  color = hotest === null ? 'transparent' : getGradientColor(hotest);
  gradient += `${color})`;
  return gradient;
}

// From minmax data of several probes, get color gradient of max temp of each dat
export function generateMinMaxGradient(fromDate, toDate, minmaxData) {
  if (!minmaxData || isEmpty(minmaxData)) {
    return 'transparent';
  }
  let gradient = [];
  const min = +fromDate / 1000;
  const max = +toDate / 1000;
  let startDate = min;
  let endDate = startDate + 24 * 60 * 60;
  let color;
  // minmaxData item: {a: '004055', b: 1642291200, h: 22.4, l: 22}
  while (startDate <= max) {
    const dayData = minmaxData.filter((d) => d.b >= startDate && d.b < endDate);
    const dayTemps = dayData.map((d) => d.h);
    const dayMax = Math.max(...dayTemps);
    color = !Number.isFinite(dayMax) || isNil(dayMax) ? 'transparent' : getGradientColor(dayMax);
    gradient.push(color);
    startDate = endDate;
    endDate = startDate + 24 * 60 * 60;
  }
  return `(to right, ${gradient.join(', ')})`;
}

export function getOpacity(date, hideDays) {
  const currentDate = +new Date();
  const sensor_ts = +new Date(date);
  const days = differenceInDays(currentDate, sensor_ts);
  if (hideDays && days >= hideDays) {
    return 0;
  }
  let opacity = 1;

  if (days >= 30) {
    opacity = 0.4;
  } else if (days >= 7) {
    opacity = 0.6;
  } else if (days >= 2) {
    opacity = 0.8;
  } else {
    opacity = 1;
  }
  return opacity;
}

export function updateNotifications(props) {
  const notifications = [];
  if (!props.expiredStates.planExpired || props.expiredStates.simExpired) {
    // GLOBAL ALERTS
    if (props.sensorsData) {
      const globalAlertProbes = props.sensorsData.filter((s) => s.alert);
      if (!isEmpty(globalAlertProbes)) {
        const notif = {
          id: `global_alert`,
          importance: 1,
          url: `/probes`,
          title: 'safety_alert',
          color: COLORS.alert,
          type: 'globalAlert',
          props: {
            nProbes: globalAlertProbes.length,
            probesIDs: globalAlertProbes.map((p) => p.sensor_id)
          }
        };
        notifications.push(notif);
      }
    }

    // BATCH TEMPERATURE NOTIFICATIONS
    if (props.batches) {
      props.batches.forEach((batch) => {
        if (
          !batch.isAdmin &&
          Number(batch.status) <= 2 &&
          batch.currentAlerts &&
          batch.currentAlerts.length > 0
        ) {
          const maxTemp = Math.max(...batch.currentAlerts.map((al) => al.temp));
          const notif = {
            id: `batch_${batch.batch_id}`,
            importance: maxTemp,
            url: `/${'batches'}/${batch.batch_id}`,
            title: 'temp_alert',
            color: getGradientColor(maxTemp),
            type: 'batch',
            props: {
              batch_name: batch.batch_name,
              completed: Number(batch.status) === 2,
              maxTemp
            }
          };
          notifications.push(notif);
        }
      });
    }
  }
  if (props.storages && !props.expiredStates.simExpired) {
    const userPlan =
      props.userInfo && props.userInfo.plans ? Number(props.userInfo.plans[0].planLevel) : 0;
    const expired =
      props.userInfo && props.userInfo.plans ? props.userInfo.plans[0].expired : false;
    // For whom to show storage notifications:
    // Plan 2 expired: show no storages
    // Plan 2: show all storages
    // Plan 4 expired: show no storages
    // Plan 4: show all storages
    const applicableStorages = expired ? [] : props.storages;
    const storagesWithProbes = applicableStorages.filter((sto) => {
      const flatProbes = sto.sensor_layout?.sensors || [];
      const stackProbes = sto.stacks ? sto.stacks.map((st) => st.sensors).flat() : [];
      return !isEmpty(flatProbes.concat(stackProbes));
    });

    const storagesWithMonitors = applicableStorages.filter((sto) => sto.monitor);
    const storagesWithSMS = applicableStorages.filter(
      (sto) =>
        sto.alertMessaging &&
        sto.alertMessaging.sms?.active &&
        sto.alertMessaging.sms?.UIDS &&
        !isEmpty(sto.alertMessaging.sms.UIDS)
    );
    const storagesWithEmail = applicableStorages.filter(
      (sto) =>
        sto.alertMessaging &&
        sto.alertMessaging.email?.active &&
        sto.alertMessaging.email?.UIDS &&
        !isEmpty(sto.alertMessaging.email.UIDS)
    );

    // There may be storages that have some criteria fulfilled but not all.
    const storagesOkMon = intersection(storagesWithProbes, storagesWithMonitors);
    const storagesOkSms = intersection(storagesWithProbes, storagesWithSMS);
    const storagesOkEmail = intersection(storagesWithProbes, storagesWithEmail);

    // For Supervisor (non Compost) users who has storages:
    // show notification if monitoring is not set up.
    if (
      userPlan >= 4 &&
      (!isEmpty(applicableStorages) ||
        (props.userInfo &&
          !(
            props.userInfo.plans &&
            props.userInfo.plans[0].segment &&
            Number(props.userInfo.plans[0].segment) === 14
          )))
    ) {
      if (isEmpty(applicableStorages)) {
        // Notify no storages -> no alerts
        const notif = {
          id: 'noSto',
          url: `/${'storages'}`,
          title: 'alert_messages_disabled',
          color: COLORS.reminder,
          type: 'noAlerts',
          props: {
            text: 'messaging_no_storages'
          }
        };
        notifications.push(notif);
      } else if (isEmpty(storagesWithProbes)) {
        // Notify no probes in storages -> no alerts
        const notif = {
          id: 'noPro',
          url: `/${'storages'}`,
          title: 'alert_messages_disabled',
          color: COLORS.reminder,
          type: 'noAlerts',
          props: {
            text: 'messaging_no_probes'
          }
        };
        notifications.push(notif);
      } else if (isEmpty(storagesWithMonitors) || isEmpty(storagesOkMon)) {
        // Notify no monitors in storages -> no alerts
        const notif = {
          id: 'noMon',
          url: `/${'storages'}`,
          title: 'alert_messages_disabled',
          color: COLORS.reminder,
          type: 'noAlerts',
          props: {
            text: 'messaging_no_monitor'
          }
        };
        notifications.push(notif);
      } else if (
        (isEmpty(storagesWithSMS) || isEmpty(storagesOkSms)) &&
        (isEmpty(storagesWithEmail) || isEmpty(storagesOkEmail))
      ) {
        // Notify no SMS enabled in storages -> no alerts
        const notif = {
          id: 'noSms',
          url: `/${'storages'}`,
          title: 'alert_messages_disabled',
          color: COLORS.reminder,
          type: 'noAlerts',
          props: {
            text: 'messaging_no_phone'
          }
        };
        notifications.push(notif);
      }
    }

    applicableStorages.forEach((storage) => {
      // TODO: Case for each rule, for now only min temp, without specification of what ref
      // Counting different sensors in current alerts
      let currentAlertProbes = storage.monitor?.rules
        .map((r) => r.currentAlerts)
        .filter((c) => !!c)
        .flat()
        .map((al) => al.sensor_id)
        .filter((value, i, arr) => arr.indexOf(value) === i);

      if (!isEmpty(currentAlertProbes)) {
        const notif = {
          id: `storage_${storage.itemID}`,
          importance: currentAlertProbes.length,
          url: `/${'storages'}/${storage.itemID}`,
          title: 'monitor_alert',
          color: COLORS.alert,
          type: 'storageMax',
          props: {
            name: storage.name,
            count: currentAlertProbes.length,
            probeIDs: currentAlertProbes
          }
        };
        notifications.push(notif);
      }
    });
  }

  if (props.userInfo.phones === undefined) {
    const notif = {
      id: 'phone_confirmation',
      importance: 100,
      url: '/account/users',
      title: 'phone_confirmation',
      color: COLORS.alert,
      text: 'phone_not_confirmed'
    };
    notifications.push(notif);
  } else {
    let confirmed = false;
    props.userInfo.phones.forEach((phone) => {
      if (phone.confirmed) {
        confirmed = true;
      }
    });
    if (!confirmed) {
      const notif = {
        id: 'phone_confirmation',
        importance: 100,
        url: '/account/users',
        title: 'phone_confirmation',
        color: COLORS.alert,
        text: 'phone_not_confirmed'
      };
      notifications.push(notif);
    }
  }

  const plan = getMaxPlan(props.userInfo.plans);
  const service = props.userInfo.service;
  const planName = getPlanName(plan, service);

  // SERVICE
  if (!!service) {
    // Trial to be expired
    if (!!service.trial) {
      if (service.trial !== 0) {
        const currentDate = endOfDay(new Date());
        const trialEnd = endOfDay(new Date(service.nextPayment.date));
        const days = differenceInDays(trialEnd, currentDate);
        if (days < 10 && days >= 1) {
          const notif = {
            id: 'reminder_trial',
            importance: 100 - days,
            url: '/account/services/renewal',
            title: 'reminder',
            color: COLORS.reminder,
            type: 'reminder',
            props: { planName, days, trial: true }
          };
          notifications.push(notif);

          // Trial is expired
        } else if (days < 1) {
          const notif = {
            id: 'reminderExpired_trial',
            importance: 100 - days,
            url: '/account/services/renewal',
            title: 'reminder',
            color: COLORS.alert,
            type: 'reminderExpired',
            props: { planName, trial: true }
          };
          notifications.push(notif);
        }
      }
    } else {
      // Service to be expired or is expired
      const currentDate = endOfDay(new Date());
      const serviceEnd = endOfDay(new Date(service.nextPayment.date));
      const days = differenceInDays(serviceEnd, currentDate);
      if (days < 30 && days >= 1) {
        const notif = {
          id: 'reminder_service',
          importance: 100 - days,
          url: '/account/services/renewal',
          title: 'reminder',
          color: COLORS.alert,
          type: 'reminder',
          props: { planName, days }
        };
        notifications.push(notif);
      } else if (days < 1) {
        const notif = {
          id: 'reminderExpired_service',
          importance: 100 - days,
          url: '/account/services/renewal',
          title: 'reminder',
          color: COLORS.alert,
          type: 'reminderExpired',
          props: { planName }
        };

        notifications.push(notif);
      }
    }
  } else {
    // Plan to be expired
    if (!plan.dueDate !== undefined && plan.planLevel !== 0) {
      const currentDate = new Date();
      const dueDate = getDateFromStr(plan.dueDate);
      const days = differenceInDays(dueDate, currentDate);
      if (days < 30) {
        const notif = {
          id: 'reminder',
          importance: 100 - days,
          url: '/account/services/checkout/?type=plan',
          title: 'reminder',
          color: COLORS.reminder,
          type: 'reminder',
          props: { planName, days }
        };
        notifications.push(notif);
      }
    }

    // Plan is expired
    if (!!plan.expired && plan.planLevel > 0) {
      const currentDate = new Date();
      const dueDate = getDateFromStr(plan.expired);
      const days = differenceInDays(dueDate, currentDate);
      const notif = {
        id: 'reminderExpired',
        importance: 100 - days,
        url: '/account/services/checkout/?type=plan',
        title: 'reminder',
        color: COLORS.alert,
        type: 'reminderExpired',
        props: { planName }
      };
      notifications.push(notif);
    }
  }

  const simDueDate = props.bsConf?.sim_exp_date;
  const simGroup = props.bsConf?.simgroup;

  // SIM
  if (simDueDate !== undefined && simGroup !== 'f') {
    const currentDate = new Date();
    const dueDate = getDateFromStr(simDueDate);
    const days = differenceInDays(dueDate, currentDate);
    if (days < 0) {
      // Sim is expired
      const notif = {
        id: 'reminderSimExpired',
        importance: 100 - days,
        url: '/account/services/checkout/?type=sim',
        title: 'reminder',
        color: COLORS.alert,
        type: 'reminderSimExpired',
        props: {}
      };
      notifications.push(notif);
    } else if (days < 30) {
      // Sim to be expired
      const notif = {
        id: 'reminderSim',
        importance: 100 - days,
        url: '/account/services/checkout/?type=sim',
        title: 'reminder',
        color: COLORS.reminder,
        type: 'reminderSim',
        props: { days }
      };
      notifications.push(notif);
    }
  }

  const apiDueDate = props.userInfo?.api_plan?.due;

  // API
  if (apiDueDate !== undefined) {
    const currentDate = new Date();
    const dueDate = getDateFromStr(apiDueDate);
    const days = differenceInDays(dueDate, currentDate);
    if (days < 0 && days > -40) {
      // API is expired. Remind for 40 days
      const notif = {
        id: 'reminderAPIExpired',
        importance: 100 - days,
        url: '/account/services/checkout/?type=api',
        title: 'reminder',
        color: COLORS.alert,
        type: 'reminderAPIExpired',
        props: {}
      };
      notifications.push(notif);
    } else if (days < 30) {
      // API to be expired
      const notif = {
        id: 'reminderAPI',
        importance: 100 - days,
        url: '/account/services/checkout/?type=api',
        title: 'reminder',
        color: COLORS.reminder,
        type: 'reminderAPI',
        props: { days }
      };
      notifications.push(notif);
    }
  }

  notifications.sort((a, b) => Number(b.importance) - Number(a.importance));
  return notifications;
}

export function getMaxPlan(plans, service) {
  if (service) {
    return service;
  } else if (!plans) {
    return { planLevel: 0, dueDate: '20300101120000' };
  }
  const maxLevel = Math.max.apply(
    Math,
    plans.map((plan) => plan.planLevel)
  );

  return plans.find((plan) => plan.planLevel === maxLevel);
}

export const planList = [
  {
    name: 'Quanturi INSIGHT',
    planLevel: 2,
    insight: true,
    segment: undefined
  },
  {
    name: 'Quanturi INSIGHT (LIMITED)',
    planLevel: 2,
    segment: undefined
  },
  {
    name: 'HAYTECH Free',
    planLevel: 0,
    segment: undefined
  },
  {
    name: 'Quanturi Premium',
    planLevel: 3,
    segment: undefined
  },
  {
    name: 'Quanturi Supervisor',
    planLevel: 4,
    segment: 0
  },
  {
    name: 'Quanturi Grain Supervisor',
    planLevel: 4,
    segment: 13
  },
  {
    name: 'Quanturi Periskop',
    planLevel: 4,
    segment: 14
  },
  {
    name: 'Quanturi LAUNDRY',
    planLevel: 4,
    segment: 15
  }
];

export function getPlanName(plan, service, adminUse, isRenewal) {
  try {
    if ((!!service && service.plan === 2) || (plan && plan.planLevel === 2)) {
      if (!adminUse) {
        if (service && service.trial && Number(service.trial) === -1 && !isRenewal) {
          return 'Quanturi INSIGHT (LIMITED)';
        }
        return 'Quanturi INSIGHT';
      } else {
        // Indicate the previous plan
        let oldPlan = '';
        if (!service.hasOwnProperty('trial') || !service.hasOwnProperty('oldPlans')) {
          oldPlan = '';
        } else {
          // Is it Premium or Free
          if (service.oldPlans === null || Number(service.oldPlans[0]?.planLevel) === 0) {
            oldPlan = '(ex-Free)';
          } else {
            // ex-premium: check if premium was valid
            const insightStarted = new Date(service.started);
            const premiumExpired = getDateFromStr(service.oldPlans[0]?.dueDate);
            let expDays = Math.ceil((premiumExpired - insightStarted) / 1000 / 3600 / 24) + 1;
            if (insightStarted < premiumExpired) {
              oldPlan = '(ex-Premium)';
            } else {
              if (expDays > -100) {
                expDays = `${premiumExpired.getMonth() + 1}/${premiumExpired.getFullYear()}`;
              } else {
                expDays = premiumExpired.getFullYear();
              }
              oldPlan = `(ex-Free ${expDays})`;
            }
          }
        }
        return `2 INSIGHT ${oldPlan}`;
      }
    }
    const segment = plan && plan?.segment ? Number(plan.segment) : 0;
    if (!plan || plan.planLevel === 0 || plan.planLevel === null) {
      return adminUse ? '0 Free' : 'HAYTECH Free';
    }
    if (plan.planLevel === 3) {
      if (!adminUse) {
        return 'Quanturi Premium';
      } else {
        // If premium expired --> "0 Free (4/2022)" with exp year
        const premiumExpiredDate = getDateFromStr(plan.dueDate);
        const now = new Date();
        let expDays = Math.ceil((premiumExpiredDate - now) / 1000 / 3600 / 24) + 1;
        if (now > premiumExpiredDate) {
          if (expDays > -100) {
            expDays = `${premiumExpiredDate.getMonth() + 1}/${premiumExpiredDate.getFullYear()}`;
          } else {
            expDays = premiumExpiredDate.getFullYear();
          }

          return `0 Free (${expDays})`;
        }
        return '3 Premium';
      }
    }
    if (plan.planLevel === 4) {
      if (segment === 14) {
        return adminUse ? '4 Periskop' : 'Quanturi Periskop';
      }
      if (segment === 13) {
        return adminUse ? '4 Supervisor Grain' : 'Quanturi Grain Supervisor';
      }
      if (segment === 15) {
        return adminUse ? '4 LAUNDRY' : 'Quanturi LAUNDRY';
      }
      return adminUse ? '4 Supervisor' : 'Quanturi Supervisor';
    }
    return '';
  } catch (e) {
    console.log(`getPlanName error: ${e.message}`);
    return 'err';
  }
}

export function getRenewalStatus(service) {
  try {
    if (!!service && service.plan === 2) {
      const noSurvey = service.surveyCompleted ? '' : ' !Survey';
      if (!service.hasOwnProperty('trial')) {
        return `${Math.round(service.period / 12)}-year${noSurvey}`;
      }
      if (service.trial === 0) {
        return `Accepted${noSurvey}`;
      }
      if (service.trial === -1) {
        return `Refused${noSurvey}`;
      }
      return `Trial${noSurvey}`;
    } else {
      return '';
    }
  } catch (e) {
    console.log(`getRenewalStatus error: ${e.message}`);
    return 'err';
  }
}

export function getPlanColor(plan, service) {
  if (service) {
    return COLORS.haytech;
  }
  const segment = plan?.segment ? Number(plan.segment) : 0;
  // HAYTECH Free
  if (!plan || plan.planLevel === 0 || plan.planLevel === null) {
    return COLORS.haytech;
  }
  // Quanturi Premium
  if (plan.planLevel === 3) {
    return COLORS.premium;
  }
  if (plan.planLevel === 4) {
    // Quanturi Compost Supervisor
    if (segment === 14) {
      return COLORS.periskopGreen;
    }
    // Quanturi Supervisor
    return COLORS.supervisor;
  }
  return '';
}

export function getServiceDate(service, dateFormat, formattedDate) {
  if (!service) {
    return 'Unkown date';
  }
  let dateObject = service.nextPayment;

  if (formattedDate) {
    return format(new Date(dateObject.date), dateFormat ?? 'yyyy-MM-dd');
  }
  return dateObject;
}

export function getPlanDate(plan, dateFormat, date, expired, checkoutDate) {
  if (date) {
    const specialDate = getDateFromStr(date);
    if (checkoutDate && specialDate < new Date()) {
      return format(new Date(), dateFormat ?? 'yyyy-MM-dd');
    }

    return format(specialDate, dateFormat ?? 'yyyy-MM-dd');
  }
  if (!plan || !plan.dueDate || expired || plan.expired) {
    return null;
  }
  const specialDate = getDateFromStr(plan.dueDate);
  if (checkoutDate && specialDate < new Date()) {
    return format(new Date(), dateFormat ?? 'yyyy-MM-dd');
  }
  return format(getDateFromStr(plan.dueDate), dateFormat ?? 'yyyy-MM-dd');
}

export function getEndDate(plan, months, dateFormat, date, expired, checkoutDate) {
  let date0;
  if (expired) {
    date0 = null;
  } else if (date) {
    date0 = getPlanDate(plan, 'yyyy-MM-dd', date, null, checkoutDate);
  } else if (plan) {
    date0 = getPlanDate(plan, null, null, null, checkoutDate);
  }
  if (date0) {
    return format(
      add(new Date(date0), {
        months: months
      }),
      dateFormat ?? 'yyyy-MM-dd'
    );
  } else {
    return format(
      add(new Date(), {
        months: months
      }),
      dateFormat ?? 'yyyy-MM-dd'
    );
  }
}

export function getPhone(phones, index) {
  if (phones === undefined || phones.length === 0) {
    return undefined;
  }
  const selectPhones = phones.filter((phone) => phone.index === index);
  if (selectPhones.length === 0) {
    return undefined;
  }
  const confirmedSelectPhones = selectPhones.filter((phone) => phone.confirmed === 1);
  if (confirmedSelectPhones.length === 0) {
    return selectPhones[0];
  }
  return confirmedSelectPhones[0];
}

export function checkPhoneNumber(phone, dialCode) {
  // EX : phone: "+33623456789" dialCode:"33" -> true
  if (phone.length < 5) {
    return false;
  }
  const purePhone = phone.split(`+${dialCode}`)[1];
  if (purePhone === undefined) {
    return false;
  }
  return purePhone.match(/\d+/g).join('').length >= 9;
}

export function getDateString(date, i18n) {
  if (date) {
    const currentDate = new Date();
    const sensor_ts = new Date(date);
    const days = differenceInDays(currentDate, sensor_ts);
    const hours = differenceInHours(currentDate, sensor_ts);
    const minutes = differenceInMinutes(currentDate, sensor_ts);
    if (days === 0) {
      if (hours === 0) {
        return `${minutes > 0 ? minutes : 0} ${i18n.minute_mini}`;
      }
      return hours === 1 ? `${hours} ${i18n.hour_mini}` : `${hours} ${i18n.hour_mini}`;
    }
    return days === 1 ? `${days} ${i18n.day_mini}` : `${days} ${i18n.day_mini}`;
  }
  return '';
}

export function getSubId(id) {
  return isNaN(id[0])
    ? `${id[0]}${parseInt(id.substring(1, 4)).toString()}${id.substring(4, 6)}`
    : `${parseInt(id.substring(0, 3)).toString()}${id.substring(3, 6)}`;
}

export function getManual(plan, lang) {
  const free = {
    en: 'https://indd.adobe.com/view/bb7647dd-eb28-4f72-b6e6-64a7451dc5d4',
    fr: 'https://indd.adobe.com/view/1960b268-e6c2-4853-80e0-1b044b713dcd',
    de: 'https://indd.adobe.com/view/33c69de6-2780-4e85-99d8-6f83518ab72c',
    nl: 'https://indd.adobe.com/view/097f16d5-4fdb-4824-bf8a-70a13558c64e',
    it: 'https://indd.adobe.com/view/631a27c6-77bd-452f-8b75-00c5a611167f',
    fi: 'https://indd.adobe.com/view/a8f7ee6b-cd89-45a4-8cdf-8a8a47ca6c40',
    hr: 'https://indd.adobe.com/view/bb7647dd-eb28-4f72-b6e6-64a7451dc5d4'
  };
  const premium = {
    en: 'https://indd.adobe.com/view/b25258a6-dfdd-4366-9416-e606dc26d95e',
    fr: 'https://indd.adobe.com/view/40bab041-98eb-4c86-8099-86b8f8f0e493',
    de: 'https://indd.adobe.com/view/dcec20b6-3a1d-4806-9110-530f1bfce99f',
    nl: 'https://indd.adobe.com/view/b41c1820-a9a1-4425-ac28-5cde8920a3c6',
    it: 'https://indd.adobe.com/view/af7b5f77-1c19-4aed-a702-9bd748259588',
    fi: 'https://indd.adobe.com/view/043929c8-b136-4b15-9e20-83c006ac817b',
    hr: 'https://indd.adobe.com/view/b25258a6-dfdd-4366-9416-e606dc26d95e'
  };
  const supervisor = {
    en: 'https://indd.adobe.com/view/522ebf34-efac-440e-ac73-41980bac13b8',
    fr: 'https://indd.adobe.com/view/7322946d-e28c-45a1-85e8-fbe9f8c0112e',
    de: 'https://indd.adobe.com/view/8fe30a68-cb2c-4546-b8f7-af8c7ce588b6',
    nl: 'https://indd.adobe.com/view/522ebf34-efac-440e-ac73-41980bac13b8',
    it: 'https://indd.adobe.com/view/522ebf34-efac-440e-ac73-41980bac13b8',
    fi: 'https://indd.adobe.com/view/17456fd2-ef6a-4193-9446-c64661f9e167',
    hr: 'https://indd.adobe.com/view/522ebf34-efac-440e-ac73-41980bac13b8',
    es: 'https://indd.adobe.com/view/522ebf34-efac-440e-ac73-41980bac13b8'
  };

  const compost_supervisor = {
    en: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Periskop_User_guide_EN.pdf?v=1717754961',
    fr: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Periskop_User_guide_FR.pdf?v=1717754961',
    de: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Periskop_User_guide_DE.pdf?v=1717754962',
    nl: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Periskop_User_guide_EN.pdf?v=1717754961',
    it: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Periskop_User_guide_EN.pdf?v=1717754961',
    fi: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Periskop_User_guide_FI.pdf?v=1717754961',
    hr: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Periskop_User_guide_HR.pdf?v=1717754961',
    es: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Periskop_User_guide_EN.pdf?v=1717754961'
  };
  const insight = {
    en: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Quanturi_insight_3D_ENG.pdf?v=1652972245',
    fr: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Quanturi_insight_3D_FR.pdf?v=1652972288',
    de: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Quanturi_insight_3D_ENG.pdf?v=1652972245',
    nl: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Quanturi_insight_3D_ENG.pdf?v=1652972245',
    it: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Quanturi_insight_3D_ENG.pdf?v=1652972245',
    fi: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Quanturi_insight_3D_ENG.pdf?v=1652972245',
    hr: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Quanturi_insight_3D_ENG.pdf?v=1652972245',
    es: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Quanturi_insight_ES.pdf?v=1684153223'
  };

  const laundry = {
    en: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Laundry_user_guide.pdf?v=1723623536',
    fr: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Laundry_user_guide.pdf?v=1723623536',
    de: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Laundry_user_guide.pdf?v=1723623536',
    nl: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Laundry_user_guide.pdf?v=1723623536',
    it: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Laundry_user_guide.pdf?v=1723623536',
    fi: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Laundry_user_guide.pdf?v=1723623536',
    hr: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Laundry_user_guide.pdf?v=1723623536',
    es: 'https://cdn.shopify.com/s/files/1/0050/4456/8137/files/Laundry_user_guide.pdf?v=1723623536'
  };

  const segment = plan.segment || 0;

  if (plan.planLevel === 0) {
    return free[lang];
  }
  if (plan.planLevel === 2) {
    return insight[lang];
  }
  if (plan.planLevel === 3) {
    return premium[lang];
  }
  if (plan.planLevel === 4) {
    if (Number(segment) === 14) {
      // console.log(lang, compost_supervisor[lang]);
      return compost_supervisor[lang];
    }
    if (Number(segment) === 15) {
      // console.log(lang, laundry[lang]);
      return laundry[lang];
    }
    return supervisor[lang];
  }
  return '';
}

export function getSignalLevel(sensorRSSI, bsRSSI, last_meas_ts) {
  let rssi = sensorRSSI;
  const meas_age = new Date().getTime() / 1000 - (last_meas_ts || 0);
  if (!rssi) {
    if (bsRSSI) {
      // use bs RSSI, if sensor RSSI is 0 (meaning no value, e.g. on sensor start-up)
      rssi = bsRSSI;
    } else {
      return -2; // No indicator if no signal
    }
  }

  if (rssi >= -70) {
    return 4; // 4/4 bars
  }
  if (rssi >= -80) {
    return 3; // 3/4 bars
  }
  if (rssi >= -90) {
    return 2; // 2/4 bars
  }
  if (rssi >= -95) {
    return 1; // 1/4 bar
  }
  // below -95: show warning (0/4 bar) only if no recent data (< 100 mins)
  return meas_age < 100 * 60 ? 1 : 0; // 0/4 bar - WARNING!
}

export function getBatteryLevel(batt_voltage) {
  if (batt_voltage >= 350) {
    return 4;
  }
  if (batt_voltage >= 340) {
    return 3;
  }
  if (batt_voltage >= 330) {
    return 2;
  }
  if (batt_voltage >= 320) {
    return 1;
  }
  return 0;
}

export function getSensorType(sensorID, nPoints, isDemoMode) {
  let type = '';
  if (sensorID) {
    let series = sensorID.substr(1, 2);
    if (sensorID.substr(0, 1) === 'T') {
      const numT = parseInt(sensorID.substr(1, 5));
      if (numT >= 800) {
        type = 'XN';
      } else if (numT >= 500) {
        type = 'T';
      } else {
        // T00000-T00499
        type = 'TXN';
      }
    } else {
      series = parseInt(series);
      if (series === 90) {
        const num = parseInt(sensorID.substr(3, 5));
        if (num < 500) {
          type = 'T';
        } else {
          type = 'XN';
        }
      } else if (
        series === 91 ||
        series === 93 ||
        series === 94 ||
        series === 95 ||
        series === 96 ||
        series === 98
      ) {
        type = 'XN';
      } else if (series === 92 || series === 97) {
        type = 'T';
      } else {
        type = nPoints && nPoints > 1 ? 'T' : 'H';
      }
    }
  }
  if (isDemoMode === 'HAY') {
    type = 'H';
  }
  return type;
}

export function formatTimeDifference(hours, i18n) {
  let text = '';
  if (hours < 1) {
    text = `${Math.ceil(hours * 60)} ${(i18n && i18n.minute_mini) || 'min'}`;
  } else if (hours <= 24) {
    text = `${Math.floor(hours)}${(i18n && i18n.hour_mini) || 'h'}`;
  } else {
    text =
      Math.floor(hours % 24) === 0
        ? `${Math.floor(hours / 24)}${(i18n && i18n.day_mini) || 'd'}` // don't show "0h"
        : `${Math.floor(hours / 24)}${(i18n && i18n.day_mini) || 'd'} ${Math.floor(hours % 24)}${
            (i18n && i18n.hour_mini) || 'h'
          }`;
  }
  return text;
}

export function getAlertSensorDepths(rule) {
  if (!rule) {
    return [];
  }
  if (rule.type === 'ABS_MAX') {
    return rule.currentAlerts?.map((al) => {
      return {
        sensor_id: al.sensor_id,
        depths: al.temperatures
          ? al.temperatures.map((temp, i) => (i > 0 && temp >= rule.settings.alertLimit ? i : null))
          : [al.temperature >= rule.settings.alertLimit ? 1 : null]
      };
    });
  } else if (rule.type === 'ABS_MIN') {
    return rule.currentAlerts?.map((al) => {
      return {
        sensor_id: al.sensor_id,
        depths: al.temperatures
          ? al.temperatures.map((temp, i) => (i > 0 && temp <= rule.settings.alertLimit ? i : null))
          : [al.temperature <= rule.settings.alertLimit ? 1 : null]
      };
    });
  } else if (rule.state && (rule.type === 'REL_MAX' || rule.type === 'REL_MIN')) {
    return rule.state?.map((s) => {
      return {
        sensor_id: s.sensor_id,
        depths: s.alerts.map((al, i) => (typeof al === 'number' ? i + 1 : null))
      };
    });
  }
  return [];
}

export function formatSensorRow(row) {
  return `${'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[Number(row)]}`;
}

export function formatFloor(floor) {
  return (
    ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII', 'XIII', 'XIV', 'XV'][
      floor
    ] || '--'
  );
}

export function formatSensorLocation(row, column) {
  return `${'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[Number(row)]}-${column + 1}`;
}

export function convertAuxData2(aux) {
  // aux object has fields a,aa,b,hh. As aux data is used both as data and conf,
  // we need to duplicte the data to fields sensor_id, temperature, temperatures, meas_ts
  aux.sensor_id = aux.a;
  aux.meas_ts = aux.b;
  aux.temperatures = aux.hh;
  aux.temperature = Math.max(...aux.hh);
  return aux;
}

export const planHandler = (plan, dispatch, demoMode) => {
  if ((plan && plan.plan === 2) || demoMode === 'HAY') {
    dispatch({ type: 'SET_INSIGHT_SERVICE', value: true });
    dispatch({ type: 'SET_PREMIUM', value: false });
    dispatch({ type: 'SET_SUPERVISOR_COMPOST', value: false });
    dispatch({ type: 'SET_SUPERVISOR', value: false });
  } else if (plan && plan.planLevel === 3) {
    dispatch({ type: 'SET_PREMIUM', value: true });
    dispatch({ type: 'SET_SUPERVISOR_COMPOST', value: false });
    dispatch({ type: 'SET_SUPERVISOR', value: false });
    dispatch({ type: 'SET_INSIGHT_SERVICE', value: false });
  } else if (plan && plan.planLevel === 4) {
    if (+plan.segment === 14 && demoMode !== 'GRAIN') {
      dispatch({ type: 'SET_SUPERVISOR_COMPOST', value: true });
      dispatch({ type: 'SET_SUPERVISOR', value: false });
    } else {
      dispatch({ type: 'SET_SUPERVISOR_COMPOST', value: false });
      dispatch({ type: 'SET_SUPERVISOR', value: true });
    }
    dispatch({ type: 'SET_INSIGHT_SERVICE', value: false });
    dispatch({ type: 'SET_PREMIUM', value: true });
  } else {
    dispatch({ type: 'SET_PREMIUM', value: false });
    dispatch({ type: 'SET_SUPERVISOR_COMPOST', value: false });
    dispatch({ type: 'SET_SUPERVISOR', value: false });
    dispatch({ type: 'SET_INSIGHT_SERVICE', value: false });
  }
};

export const handleExpired = (plan, bsConf) => {
  let results = { planExpired: false, serviceExpired: false, simExpired: false };
  if (
    plan &&
    (!!plan.expired ||
      (plan.plan === 2 && endOfDay(new Date(plan.nextPayment.date)) <= endOfDay(new Date())))
  ) {
    results = { ...results, planExpired: true };
  }

  if (
    bsConf?.sim_exp_date &&
    bsConf?.simgroup !== 'f' &&
    getDateFromStr(bsConf?.sim_exp_date) < sub(new Date(), { days: 1 })
  ) {
    results = { ...results, simExpired: true };
  }
  return results;
};

// If batt < 3.3V & no meas in 3h -> batt is likely dead
export const isLowBattery = (lastData) => {
  if (!lastData || !lastData.sensor_id || !lastData.batt_voltage) {
    // dataless or aux sensor, or voltage is null (or 0): G4 meas with no voltage
    return false;
  }
  let battLow = lastData
    ? lastData.batt_voltage < 340 && Number(lastData.meas_ts) < +new Date() / 1000 - 3 * 60 * 60
    : false;
  if (battLow) {
    // Voltage value is low. Still need to check the reliability of the value.
    // This can be told by sensor ID. Only consider IDs >= 5400, or T-series ID >=T00150
    // Before that, batt level figures given by the sensors are not necessary coherent or reliable
    if (lastData.sensor_id.substr(0, 1) === 'T') {
      battLow = Number(lastData.sensor_id.substr(1, 6)) >= 150;
    } else {
      battLow = Number(lastData.sensor_id) >= 5400;
    }
  }
  return battLow;
};

// Check if alert messaging is not enabled for a messagingHolder (storage or batch).
// - object has no alertMessaging
// - alertMessaging is not active
// - there are no messaging recipients
export const isSMSMessagingOff = (messagingHolder) => {
  const smsOn =
    messagingHolder?.alertMessaging?.sms?.active &&
    messagingHolder?.alertMessaging?.sms?.UIDS?.length > 0;
  const emailOn =
    messagingHolder?.alertMessaging?.email?.active &&
    messagingHolder?.alertMessaging?.email?.UIDS?.length > 0;
  return !smsOn && !emailOn;
};

export const calcOverheadHeight = () => {
  const adminH = document.getElementById('adminBar')
    ? document.getElementById('adminBar').offsetHeight
    : 0;
  const toolH = document.getElementById('mainToolbar')
    ? document.getElementById('mainToolbar').offsetHeight
    : 0;
  const bstabsH = document.getElementById('bsTabs')
    ? document.getElementById('bsTabs').offsetHeight
    : 0;
  const storagetoolH = 52; // use fixed number. Div height is not available while rendering layout

  // = document.getElementById('storageToolbar')
  //   ? document.getElementById('storageToolbar').offsetHeight
  //   : 0;
  const storagetabsH = 48; // use fixed number. Div height is not available while rendering layout
  // = document.getElementById('storageTabs')
  //   ? document.getElementById('storageTabs').offsetHeight
  //   : 0;

  return adminH + toolH + bstabsH + storagetoolH + storagetabsH;
};

export const createDefaultStack = (columns, rows, pileHeight) => {
  const stack = {
    index: 0,
    nColumns: columns,
    nRows: rows,
    piles: pileHeight
      ? Array.from(Array(rows).keys())
          .map((row) =>
            Array.from(Array(columns).keys()).map((col) => ({ c: col, r: row, h: pileHeight }))
          )
          .flat()
      : [],
    sensors: []
  };
  return stack;
};

export const getQualityColor = (percentage, HDD) => {
  // const cubeSize = !isNil(percentage)
  //   ? Math.max(0.25, (Math.ceil((0.9 - percentage) * 10) / 10) * 0.8)
  //   : 0.15;
  if (isNil(percentage)) {
    return { color: COLORS.altoGray, bars: 0, size: 0.15, intensity: 1.5 };
  }
  // if (percentage >= 0.9) {
  if (HDD < 140) {
    return { color: COLORS.quality4, bars: 5, size: 0.27, intensity: 1.5 };
  }
  // if (percentage >= 0.7) {
  if (HDD < 280) {
    return { color: COLORS.quality3, color3D: '#B7C163', bars: 4, size: 0.36, intensity: 1.6 };
  }
  // if (percentage >= 0.5) {
  if (HDD < 420) {
    return { color: COLORS.quality2, color3D: '#6A5C30', bars: 3, size: 0.45, intensity: 1.3 };
  }
  // if (percentage >= 0.3) {
  if (HDD < 640) {
    return { color: COLORS.quality1, color3D: '#4D3E30', bars: 2, size: 0.55, intensity: 1.3 };
  }
  return { color: COLORS.quality0, color3D: '#3A3030', bars: 1, size: 0.6, intensity: 1.5 };
};

export const getDaysToQualityDrop = (currentHDD, currentBars, lastMeas) => {
  const nextRateLimits = [140, 280, 420, 640];
  const currentTemp = lastMeas?.temperature;
  const measTimestamp = lastMeas?.meas_ts || 0;
  const measAgeHours = (new Date() - new Date(measTimestamp * 1000)) / 1000 / 60 / 60;

  if (!lastMeas || measAgeHours > 24 || currentBars === 1 || currentTemp <= 30) {
    return 999;
  }
  const nextRate = nextRateLimits[5 - currentBars];
  const HDDtoNextRate = Math.max(0, nextRate - currentHDD);
  const daysWithCurrentTemp = HDDtoNextRate / (currentTemp - 30);
  return daysWithCurrentTemp;
};

export const isQualityAlert = (probeQuality) => {
  return probeQuality && probeQuality.daysToDrop && probeQuality.daysToDrop <= 2;
};
export const isQualityWarning = (probeQuality) => {
  if (probeQuality?.isDemo) {
    // more tolerant in demo to avoid excessive warnings based on demo data
    return probeQuality.daysToDrop && probeQuality.daysToDrop > 2 && probeQuality.daysToDrop <= 4;
  }
  return (
    probeQuality &&
    probeQuality.daysToDrop &&
    probeQuality.daysToDrop > 2 &&
    probeQuality.daysToDrop <= 7
  );
};
