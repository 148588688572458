import { Grid, ListItem, ListItemText } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { adminStyles } from 'utils/sharedStyles';
import { format } from 'date-fns';
// import { COLORS } from 'utils/colors';
import { getReplacementHistory } from 'api/serverAPI';
import { InlineIcon } from '@iconify/react';
import shieldAccount from '@iconify/icons-mdi/shield-account';

const ReplacementHistory = ({ bsId }) => {
  const classes = adminStyles();
  const dateFormat = 'dd.MM.yyyy';
  const [history, setHistory] = useState(null);

  useEffect(() => {
    const getHistory = async () => {
      const hist = await getReplacementHistory({ BS_ID: bsId });
      if (!isEmpty(hist)) {
        setHistory(hist);
      } else {
        setHistory(null);
      }
    };
    getHistory();
  }, [bsId]);

  return history ? (
    <Grid container className={classes.gridContainer}>
      {history.map((rep, i) => (
        <ListItem key={i}>
          <ListItemText
            primary={
              i === 0 ? (
                <div style={{ paddingTop: 0, paddingBottom: 10 }}>
                  {bsId} Replacement history
                  <InlineIcon
                    style={{ marginLeft: 5, marginBottom: -3 }}
                    height={20}
                    icon={shieldAccount}
                  />
                </div>
              ) : null
            }
            secondary={
              <div
                style={{
                  marginTop: i === 0 ? 0 : -10,
                  marginBottom: 0,
                  paddingTop: 0,
                  paddingBottom: 0
                }}
              >
                <span
                  style={{ fontWeight: rep.replaced === bsId ? 700 : 400 }}
                >{`${rep.replaced}`}</span>
                <span
                  style={{ fontWeight: 400, marginLeft: 5, marginRight: 5 }}
                >{`${String.fromCharCode(8658)}`}</span>
                <span
                  style={{ fontWeight: rep.BS_ID === bsId ? 700 : 400, marginRight: 10 }}
                >{`${rep.BS_ID}`}</span>
                <span style={{ fontSize: 12 }}>{`${format(
                  new Date(rep.replaced_date),
                  dateFormat
                )} / ${rep.admin}`}</span>
              </div>
            }
          />
        </ListItem>
      ))}
    </Grid>
  ) : null;
};

export default ReplacementHistory;
